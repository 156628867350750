import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { breadContent, breadFinanceText } from './content'
import LazyImage from '../../common/LazyImage'
import initializeBread from '../../../bread'
import { EargoButtonV2, DotSpinLoading } from '@eargo/eargo-components'
import './bread_modal.scss'

const { logo, closeLogo, title, subtitleP1, subtitleP2, subtitleP3, breadbutton, m1, m2, BREAD_24 } = breadContent
const BreadModal = ({ open, variant, updateState, cart }) => {
  const [showModal, setShowModal] = useState(false)
  const { user, shipAddress, billAddress } = cart

  const BREAD = BREAD_24

  const m3 = breadFinanceText(variant.price, variant.finance_price, variant.discount)

  const removeNumbers = str => str.replace(/\d+/g, '')
  const removeNonNumeric = str => str.replace(/\D/g, '')
  const placements = [{
    financingType: 'installment',
    locationType: 'product',
    domID: 'bread-checkout-btn',
    allowCheckout: false,
    order: {
      items: [
        {
          name: variant.product.name,
          sku: variant.sku,
          unitPrice: {
            value: variant.price * 100,
            currency: 'USD'
          },
          unitTax: {
            value: 0,
            currency: 'USD'
          },
          brand: 'Eargo',
          quantity: 1,
          shippingDescription: 'Ground',
          shippingCost: {
            value: 0,
            currency: 'USD'
          }
        }
      ],
      subTotal: {
        value: (cart && cart.total ? cart.itemTotal : variant.price) * 100,
        currency: 'USD'
      },
      totalTax: {
        value: (cart && cart.total ? cart.additionalTaxTotal : 0) * 100,
        currency: 'USD'
      },
      totalShipping: {
        value: (cart && cart.total ? cart.shipmentTotal : 0) * 100,
        currency: 'USD'
      },
      totalDiscounts: {
        value: (cart && cart.total ? (-cart.promoTotal) : variant.discount) * 100,
        currency: 'USD'
      },
      totalPrice: {
        value: (cart && cart.total ? cart.total : (variant.price - variant.discount)) * 100,
        currency: 'USD'
      }
    }
  }]

  const buyer = {
    givenName: user && user.firstName ? removeNumbers(user.firstName) : '',
    familyName: user && user.familyName ? removeNumbers(user.familyName) : '',
    email: user && user.email ? user.email : '',
    phone: user && user.phone ? removeNonNumeric(user.phone) : '',
    billingAddress: {
      address1: billAddress ? billAddress.address1 : '',
      address2: billAddress ? billAddress.address2 : '',
      country: billAddress ? billAddress.country.iso : '',
      locality: billAddress ? billAddress.city : '',
      region: billAddress ? billAddress.state.abbr : '',
      postalCode: billAddress ? billAddress.zipcode : ''
    },
    shippingAddress: {
      address1: shipAddress ? shipAddress.address1 : '',
      address2: shipAddress ? shipAddress.address2 : '',
      country: shipAddress ? shipAddress.country.iso : '',
      locality: shipAddress ? shipAddress.city : '',
      region: shipAddress ? shipAddress.state.abbr : '',
      postalCode: shipAddress ? shipAddress.zipcode : ''
    }
  }

  useEffect(() => {
    async function initBread () {
      const breadData = {
        placements: placements,
        buyer: buyer
      }
      await initializeBread(breadData)
      setShowModal(true)
    }
    initBread()
  }, [])

  const handleBread = async () => {
    updateState(false)
    await window.BreadPayments.openExperienceForPlacement(placements)
    const interval = setInterval(() => {
      try {
        const breadElem = document.querySelector('[id*="zoid-checkout-component"]')
        if (!breadElem || (breadElem && breadElem.style.display === 'none')) {
          if (breadElem) {
            breadElem.remove()
          }
          clearInterval(interval)
        }
      } catch {
        const breadElem = document.querySelector('[id*="zoid-checkout-component"]')
        if (!breadElem) {
          clearInterval(interval)
        }
      }
    }, 4000)
  }

  const section = (data, key) => {
    const { sectiontitle, sectioncontent, alt, img } = data
    return <div className='section' key={key}>
      <div className='icon'>
        <LazyImage src={img} alt={alt} />
        <div className='body typographyV2'>{sectiontitle}</div>
      </div>
      <div className='copy'>
        <div className='body typographyV2'>{sectioncontent}</div>
      </div>
    </div>
  }

  return (
    <div className={`bread_modal_container ${open ? '' : 'clsd'}`} data-testid='bread-modal-testid'>
      {!showModal && <DotSpinLoading />}
      <div className={`modDl ${showModal ? '' : 'hide'}`}>
        <div className='modCn'>
          <div className='modBd'>
            <div className='brdHd'>
              <img className='logo' src={logo}/>
              <img src={closeLogo} className='close' onClick={() => updateState(false)} />
            </div>
            <h5 className='typographyV2 brdTi'>{title}</h5>
            <div className='body typographyV2 brdSt' data-testid='bread-modal-testid-2'>
              {subtitleP1}&nbsp;
              <a href="https://www.getbread.com/privacy-policy/">
                {subtitleP2}
              </a>
              &nbsp;{subtitleP3}
            </div>
            <div className='bread_modal_body'>
              {BREAD.map((data, index) => section(data, index))}
              <div id='bread-checkout-btn' className='hidden_bread_btn' />
              <div className='brdSe'>
                {/* <div className="price"> */}
                  <EargoButtonV2 className='primary stretch' handleOnClick={handleBread} >
                    {breadbutton}
                  </EargoButtonV2>
                {/* </div> */}
              </div>
              <p className='body typographyV2 text-color'>
                {m1}
                <br />
                {m2}
              </p>
              <p className='typeV2-body-caption typographyV2 caption-color'>
                {m3}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BreadModal.propTypes = {
  open: PropTypes.any,
  variant: PropTypes.any,
  updateState: PropTypes.any,
  cart: PropTypes.any
}

export default BreadModal

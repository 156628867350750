const constants = {
  playground: {
    CBS_API_JS: 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout-sandbox.getbread.com/bread.js',
    BREAD_API_URL: 'https://api-sandbox.getbread.com/transactions/actions/',
    BREAD_API_KEY: '7ddb8fb0-44b4-4958-9ced-e5b91ace71c8',
    BREAD_API_PASS: 'd1457906-b292-48e6-ae1c-ba9c03fef47b',
    BREAD2_API_JS: 'https://connect-preview.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api-preview.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: '0aed9639-05a9-40e5-9867-0cc07cbbf157',
    CORP_URL: 'https://web-qa4.eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'https://playground.eargo.com/',
    V2_SERVER: 'https://playground.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu', // 'MuaKfEiHsz8EWHDaqmxRcVnzHZqB5jnoBskGbVtC',
    MENTION_ME_SERVER: 'https://tag-demo.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AcelreFBbPArTb9uEaMaQEeJjX3pTkQWLQJCRrLV4eezcsJECszUnDhKoctKU0cfp-Kjs_T3SeUYTiE1',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'https://playground.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop-dev4',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb-beta.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'GmlsicL1lN13OjvJNSzd92hzCmO2WDdW',
    AUTH0DOMAIN: 'login-qa.eargo.com',
    AUTH0CONNECTION: 'Eargo-dev-DB'
  },
  development: {
    CBS_API_JS: 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout-sandbox.getbread.com/bread.js',
    BREAD_API_URL: 'https://api-sandbox.getbread.com/transactions/actions/',
    BREAD_API_KEY: '7ddb8fb0-44b4-4958-9ced-e5b91ace71c8',
    BREAD_API_PASS: 'd1457906-b292-48e6-ae1c-ba9c03fef47b',
    BREAD2_API_JS: 'https://connect-preview.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api-preview.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: '0aed9639-05a9-40e5-9867-0cc07cbbf157',
    CORP_URL: 'https://eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'http://playground.eargo.com/',
    V2_SERVER: 'http://playground.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu', // 'MuaKfEiHsz8EWHDaqmxRcVnzHZqB5jnoBskGbVtC',
    MENTION_ME_SERVER: 'https://tag-demo.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AcelreFBbPArTb9uEaMaQEeJjX3pTkQWLQJCRrLV4eezcsJECszUnDhKoctKU0cfp-Kjs_T3SeUYTiE1',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'http://playground.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop-dev4',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb-beta.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'GmlsicL1lN13OjvJNSzd92hzCmO2WDdW',
    AUTH0DOMAIN: 'login-qa.eargo.com',
    AUTH0CONNECTION: 'Eargo-dev-DB'
  },
  production: {
    CBS_API_JS: 'https://flex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout.getbread.com/bread.js',
    BREAD_API_URL: 'https://api.getbread.com/transactions/actions/',
    BREAD_API_KEY: '1a8b4faf-53a6-4788-8b93-b2d464aa2bc3',
    BREAD_API_PASS: '47f4701a-3c1f-49c0-98d7-061b1a536e09',
    BREAD2_API_JS: 'https://connect.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: 'ae02a4e8-1cfd-4046-91c3-d62f2d824127',
    CORP_URL: 'https://www.eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'https://shop.eargo.com/',
    V2_SERVER: 'https://shop.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu',
    MENTION_ME_SERVER: 'https://tag.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AVGQ1TxUbc4u0Fp6p8sjI3AHitQHQbxS8FYRN7iTsx2bsBF5TgkE4VFjIAWmKP0fSvuvE129heLGRaNv',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'https://shop.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'xgsCIeYZm8bHelrskOXcB67pT4bUn43Y',
    AUTH0DOMAIN: 'login.eargo.com',
    AUTH0CONNECTION: 'Ecom-db'
  },
  dev4: {
    CBS_API_JS: 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout-sandbox.getbread.com/bread.js',
    BREAD_API_URL: 'https://api-sandbox.getbread.com/transactions/actions/',
    BREAD_API_KEY: '7ddb8fb0-44b4-4958-9ced-e5b91ace71c8',
    BREAD_API_PASS: 'd1457906-b292-48e6-ae1c-ba9c03fef47b',
    BREAD2_API_JS: 'https://connect-preview.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api-preview.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: '0aed9639-05a9-40e5-9867-0cc07cbbf157',
    CORP_URL: 'https://eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'https://shop-dev4.eargo.com/',
    V2_SERVER: 'https://shop-dev4.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu', // 'MuaKfEiHsz8EWHDaqmxRcVnzHZqB5jnoBskGbVtC',
    MENTION_ME_SERVER: 'https://tag-demo.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AcelreFBbPArTb9uEaMaQEeJjX3pTkQWLQJCRrLV4eezcsJECszUnDhKoctKU0cfp-Kjs_T3SeUYTiE1',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'https://shop-dev4.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop-dev4',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb-beta.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'GmlsicL1lN13OjvJNSzd92hzCmO2WDdW',
    AUTH0DOMAIN: 'login-qa.eargo.com',
    AUTH0CONNECTION: 'Eargo-dev-DB'
  },
  dev5: {
    CBS_API_JS: 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout-sandbox.getbread.com/bread.js',
    BREAD_API_URL: 'https://api-sandbox.getbread.com/transactions/actions/',
    BREAD_API_KEY: '7ddb8fb0-44b4-4958-9ced-e5b91ace71c8',
    BREAD_API_PASS: 'd1457906-b292-48e6-ae1c-ba9c03fef47b',
    BREAD2_API_JS: 'https://connect-preview.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api-preview.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: '0aed9639-05a9-40e5-9867-0cc07cbbf157',
    CORP_URL: 'https://web-dev5.eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'https://shop-dev5.eargo.com/',
    V2_SERVER: 'https://shop-dev5.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu', // 'MuaKfEiHsz8EWHDaqmxRcVnzHZqB5jnoBskGbVtC',
    MENTION_ME_SERVER: 'https://tag-demo.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AcelreFBbPArTb9uEaMaQEeJjX3pTkQWLQJCRrLV4eezcsJECszUnDhKoctKU0cfp-Kjs_T3SeUYTiE1',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'https://shop-dev5.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop-dev4',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb-beta.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'GmlsicL1lN13OjvJNSzd92hzCmO2WDdW',
    AUTH0DOMAIN: 'login-qa.eargo.com',
    AUTH0CONNECTION: 'Eargo-dev-DB'
  },
  stage4: {
    CBS_API_JS: 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout-sandbox.getbread.com/bread.js',
    BREAD_API_URL: 'https://api-sandbox.getbread.com/transactions/actions/',
    BREAD_API_KEY: '7ddb8fb0-44b4-4958-9ced-e5b91ace71c8',
    BREAD_API_PASS: 'd1457906-b292-48e6-ae1c-ba9c03fef47b',
    BREAD2_API_JS: 'https://connect-preview.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api-preview.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: '0aed9639-05a9-40e5-9867-0cc07cbbf157',
    CORP_URL: 'https://web-staging4.eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'https://shop-staging4.eargo.com/',
    V2_SERVER: 'https://shop-staging4.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu', // 'MuaKfEiHsz8EWHDaqmxRcVnzHZqB5jnoBskGbVtC',
    MENTION_ME_SERVER: 'https://tag-demo.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AcelreFBbPArTb9uEaMaQEeJjX3pTkQWLQJCRrLV4eezcsJECszUnDhKoctKU0cfp-Kjs_T3SeUYTiE1',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'https://shop-staging4.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop-dev4',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb-beta.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'BvyrVRwbcjJ2n8z6nBeHAM3TnTnk4Ykn',
    AUTH0DOMAIN: 'login-staging.eargo.com',
    AUTH0CONNECTION: 'Ecom-db'
  },
  qa4: {
    CBS_API_JS: 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout-sandbox.getbread.com/bread.js',
    BREAD_API_URL: 'https://api-sandbox.getbread.com/transactions/actions/',
    BREAD_API_KEY: '7ddb8fb0-44b4-4958-9ced-e5b91ace71c8',
    BREAD_API_PASS: 'd1457906-b292-48e6-ae1c-ba9c03fef47b',
    BREAD2_API_JS: 'https://connect-preview.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api-preview.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: '0aed9639-05a9-40e5-9867-0cc07cbbf157',
    CORP_URL: 'https://web-qa4.eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'https://shop-qa4.eargo.com/',
    V2_SERVER: 'https://shop-qa4.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu', // 'MuaKfEiHsz8EWHDaqmxRcVnzHZqB5jnoBskGbVtC',
    MENTION_ME_SERVER: 'https://tag-demo.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AcelreFBbPArTb9uEaMaQEeJjX3pTkQWLQJCRrLV4eezcsJECszUnDhKoctKU0cfp-Kjs_T3SeUYTiE1',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'https://shop-qa4.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop-dev4',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb-beta.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'GmlsicL1lN13OjvJNSzd92hzCmO2WDdW',
    AUTH0DOMAIN: 'login-qa.eargo.com',
    AUTH0CONNECTION: 'Eargo-dev-DB'
  },
  beta: {
    CBS_API_JS: 'https://flex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
    BREAD_API_JS: 'https://checkout.getbread.com/bread.js',
    BREAD_API_URL: 'https://api.getbread.com/transactions/actions/',
    BREAD_API_KEY: '1a8b4faf-53a6-4788-8b93-b2d464aa2bc3',
    BREAD_API_PASS: '47f4701a-3c1f-49c0-98d7-061b1a536e09',
    BREAD2_API_JS: 'https://connect.breadpayments.com/sdk.js',
    BREAD2_API_URL: 'https://api.platform.breadpayments.com/api',
    BREAD_INTEGRATION_KEY: 'ae02a4e8-1cfd-4046-91c3-d62f2d824127',
    CORP_URL: 'https://www.eargo.com/',
    INVESTOR_URL: 'https://ir.eargo.com',
    HANDLER: {
      FB: 'eargohearing',
      TWITTER: 'eargo',
      INSTA: 'eargo_hearing',
      PINTREST: 'hearwitheargo',
      YOUTUBE: 'UCNEd3jeMQdapvGmVKGjZmjw',
      LINKEDIN: 'company/eargo'
    },
    SERVER_URL: 'https://shop-beta.eargo.com/',
    V2_SERVER: 'https://shop-beta.eargo.com/v2/',
    INSPECTLET_ID: '33440362',
    YOTPO_KEY: 'BWputzT2UGs7ktYgR5Ts2a5mlVphGNCjlSiAGBUu',
    MENTION_ME_SERVER: 'https://tag.mention-me.com/api/v2/',
    MENTION_ME_ID: 'mmc251d4cd',
    PAYPAL_CLIENT_ID: 'AVGQ1TxUbc4u0Fp6p8sjI3AHitQHQbxS8FYRN7iTsx2bsBF5TgkE4VFjIAWmKP0fSvuvE129heLGRaNv',
    PAYPAL_SDK_URL: 'https://www.paypal.com/sdk/js',
    BASE_URL: 'https://shop-beta.eargo.com/',
    APPLE_PAY_MERCHANT_IDENTIFIER: 'merchant.com.eargo.shop',
    CLEMENTAINEIFRAMEURL: 'https://clementineweb.azurewebsites.net/Screening/Index?d=L4htxQetjsH381DduwaIRVy_eDzfMNf7xh4NA2Wee0CJGpEe6GMITfUcvgQmUCRptXt9Ob3TkQAdLUDm9NGOdTS6Dtu92jPFxo2pDJkdd_DuJZFlmObcexHqJ1ZAM11.qqFDqCAxjY0CixDIqUhXHc8YnYy89XjyUGZl5CrV_cShKU15Kf5ii4T8bq7OKLrohUJxp330Lf03QWPSRLF3J4e3B5AXwQpHftUAvyTbjgvJ_fxeOG0qUSbwARlOUAJhq0WbamePY166aUOdFXFOsD.FZ1UIYFcMSSHg_Q7yXyaAvE7TGnWP3dddaW3wQHaPopRBRG92LQsd6B2Y0PPz0iPX7uM41FZCuiFoEvSoXhNmR.bM5kFYOoQKduvfKCnGwcc2s_I_X.2VrSfh7eiO9kH5ovFxUE.HhXjPBWGemQc-&culture=en-US',
    AUTH0CLIENTID: 'xgsCIeYZm8bHelrskOXcB67pT4bUn43Y',
    AUTH0DOMAIN: 'login.eargo.com',
    AUTH0CONNECTION: 'Ecom-db'
  },
  default: {
    PRODUCT_NAMES: { // discontinuing this object in favor of SKU
      EARGO_NEO: 'Eargo Neo',
      EARGO_5: 'Eargo 5',
      EARGO_6: 'Eargo 6',
      EARGO_7: 'Eargo 7',
      EARGO_MAX: 'Eargo Max',
      EARGO_NEO_HIFI: 'Eargo Neo HiFi',
      FLEXI_FIBERS: 'Flexi Fibers',
      FLEXI_DOMES: 'Flexi Domes',
      FLEXI_PALMS: 'Flexi Palms',
      FLEXI_TETRAPALMS: 'Flexi TetraPalms',
      EXTENDED_WARRANTY: 'Extended Warranty',
      WAX_GUARD: 'Wax Guard Replacement Tool',
      ADAPTOR_CABLE: 'Additional Charger along with Power Cable and Adaptor',
      MIC_CAPS: 'Mic Caps',
      CLOSE_PETAL_5: 'Closed Petals for Eargo 5, 6, and 7',
      OPEN_PETAL_5: 'Open Petals for Eargo 5, 6, and 7',
      CHARGER: 'Charger Bundle',
      CHARGING_CABLE: 'Charging Cable',
      USB_CORD: 'USB-C to USB-A Cord',
      POWER_Cable_Adaptor: 'Power Cable and Adaptor',
      DEEP_CLEANING_KIT: 'Deep Cleaning Kit'
    },
    ROUTES: {
      ROOT: '/eargo-5',
      EARGO_NEO: '/eargo-neo',
      EARGO_MAX: '/eargo-max',
      EARGO_PLUS: '/eargo-plus',
      EARGO_NEO_HIFI: '/eargo-neo-hifi',
      EARGO_5: '/eargo-5',
      EARGO_6: '/eargo-6',
      ADDRESS: '/old-address',
      NEW_CHECKOUT: '/address',
      REVIEW: '/review',
      CONFIRMATION: '/orders',
      LOGIN: '/login',
      ACCESSORIES: '/accessories',
      PROMO_BANNER: '/promo-banner'
    },
    BREAD_TARGETING: {
      LARGE: '22ca71d5-5d66-4048-bac0-8d17801255c6',
      SMALL: '22ca71d5-5d66-4048-bac0-8d17801255c6',
      PRICE: 2400.00
    }
  }
}
export const CART_STATES = {
  ADDRESS: 'address',
  PAYMENT: 'payment',
  COMPLETE: 'complete',
  PENDING: 'pending-payment',
  PENDING_APPROVAL: 'pending-approval'
}

export const SKU = {
  EARGO_SE: '99-0155-001',
  LINK_BY_EARGO: '99-0173-001',
  EARGO_7: '99-0156-001',
  EARGO_6: '99-0147-001',
  EARGO_5: '99-0114-001',
  EARGO_NEO: '99-0076-001',
  EARGO_MAX: '99-0068-001',
  EARGO_NEO_HIFI: '99-0102-001',
  FLEXI_PALMS_SMALL: '99-0113-001',
  PETALS_FOR_EARGO_5: '99-0135-001',
  PETALS_FOR_EARGO: '99-0136-001',
  FLEXI_FIBERS_5: 'A012',
  FLEXI_DOMES_5: 'Flexi Domes',
  FLEXI_PALMS_5: 'Flexi Palms',
  MIC_CAPS: '99-0133-001',
  CLOSE_PETAL_5: '99-0138-001',
  CLOSE_PETAL: '99-0140-001',
  CABLE_ADAOTOR: '99-0112-001',
  FLEXI_PALMS_REGULAR: '99-0081-001',
  FLEXI_PALMS_LARGE: '99-0082-001',
  FLEXI_FIBERS_REGULAR: '99-0044-001',
  FLEXI_FIBERS_LARGE: '99-0045-001',
  FLEXI_DOMES_REGULAR: '99-0061-001',
  FLEXI_DOMES_LARGE: '99-0062-001',
  FLEXI_TETRAPALMS_REGULAR: '99-0110-001',
  FLEXI_TETRAPALMS_LARGE: '99-0111-001',
  EXTENDED_WARRANTY: '99-0036-001',
  WAX_GUARD: '99-0046-001',
  CHARGER_NEO: 'Charger - Neo',
  CHARGER_MAX: '99-0073-001',
  CHARGER_CABLE_NEO: 'Charging Cable - Neo',
  CHARGER_CABLE_MAX: 'Charging Cable - Max/Plus',
  USB_C_CORD: '99-0121-001',
  CHARGER_CABLE_ADAPTER: '99-0146-001',
  CABLE_ADAPTER: '99-0129-001',
  DEEP_CLEANING_KIT: '99-0171-002',
  EARGO_SE_SMALL_TRUMPETS: '99-0164-001',
  EARGO_SE_MEDIUM_TRUMPETS: '99-0165-001',
  EARGO_SE_LARGE_TRUMPETS: '99-0166-001',
  LINK_BY_EARGO_SMALL_EAR_TIPS: '99-0174-001',
  LINK_BY_EARGO_MEDIUM_EAR_TIPS: '99-0174-002',
  LINK_BY_EARGO_LARGE_EAR_TIPS: '99-0174-003'
}

export const PAYMENT_METHODS = {
  BREAD: 'Bread',
  PAYPAL: 'PayPal',
  CBS: 'Cybersource',
  FEDERAL: 'Federal-Insurance',
  APPLEPAY: 'Apple'
}

export const ROUTES = {
  ROOT: '/eargo-7',
  EARGO_NEO: '/eargo-neo',
  EARGO_MAX: '/eargo-max',
  EARGO_PLUS: '/eargo-plus',
  EARGO_5: '/eargo-5',
  EARGO_6: '/eargo-6',
  EARGO_7: '/eargo-7',
  EARGO_NEO_HIFI: '/eargo-neo-hifi',
  ADDRESS: '/address',
  NEW_CHECKOUT: '/address',
  REVIEW: '/review',
  ORDER_CONFIRMATION: '/orders/:order_number',
  CONFIRMATION: '/orders',
  LOGIN: '/login',
  LOGIN_DEVICEID: '/login/:deviceId',
  ACCESSORIES: '/accessories',
  ACCESSORIES_DESCRIPTION: '/accessories/:id',
  PROMO_BANNER: '/promo-banner',
  FORGOT_PASSWORD: '/forgot-password',
  INSURANCE_CHECKOUT: '/insurance-checkout',
  VERIFY_OTP: '/verify-otp',
  RESET_PASSWORD: '/reset-password/:reset_password_token',
  MY_INFO: '/my-info',
  MY_ORDERS: '/my-info/order',
  MY_DASHBOARD: '/my-info/dashboard',
  INSURANCE_FORM: '/insurance-form',
  FEDERAL_FORM: '/fedform',
  SUPPORT_FORM: '/my-info/support/form',
  SUPPORT_ID: '/my-info/support/:id',
  ACTIVATE_ACCOUNT: '/activate-account',
  ACTIVATE_ACCOUNT_ID: '/activate-account/:id',
  COMPLETED: '/completed',
  TRACK_ORDER: '/track-order/:id',
  INSURANCE_HEARING_SCREEN: '/insurance-hearing-screening',
  SIGNUP: '/signup',
  LOGIN_OPTION: '/login-option',
  VERIFY_LOGIN_OTP: '/verify-login-otp/:id',
  RESEND_LOGIN_OTP: '/Resend-login-otp',
  CHANGE_PASSWORD: '/change-password'
  // DASHBOARD: '/dashboard'
}

export const YOTPO_PRODUCT_ID = {
  [ROUTES.EARGO_NEO]: 'E089-neo',
  [ROUTES.EARGO_MAX]: 'E068-max',
  [ROUTES.EARGO_NEO_HIFI]: 'E020-NeoHiFi',
  [ROUTES.EARGO_5]: '99-0114-001',
  [ROUTES.EARGO_6]: '99-0147-001',
  [ROUTES.EARGO_7]: '99-0156-001'

}

export default constants

export const DATE_FORMAT = 'MM/DD/YYYY'
export const DATE_FORMAT_1 = 'MMM. DD, YYYY'
export const SET_WIDTH_FOR_TABLET = 1112
export const SET_WIDTH_FOR_MOBILE = 600
export const TOASTER_AUTOCLOSE = 5000
export const INTECOM_APP_ID = 'a97ebxkh'
export const TIMEOUT = 2000

export const { REACT_APP_NODE_ENV } = process.env
const windowEnv = window.config && window.config.env ? window.config.env : false
export const NODE_ENV = windowEnv || REACT_APP_NODE_ENV || 'stage4'

export const SOCIAL_HANDLER = constants[NODE_ENV].HANDLER
const blogUrl = constants[NODE_ENV].CORP_URL + 'blog'
const investorUrl = constants[NODE_ENV].INVESTOR_URL
export const addressUrl = constants[NODE_ENV].CORP_URL
export const BASE_URL = constants[NODE_ENV].BASE_URL
export const SERVER_URL = constants[NODE_ENV].SERVER_URL
export const FEEDBACK_SERVER = constants[NODE_ENV].V2_SERVER
export const YOTPO_KEY = constants[NODE_ENV].YOTPO_KEY
export const MENTION_ME_ID = constants[NODE_ENV].MENTION_ME_ID
export const MENTION_ME_SERVER = constants[NODE_ENV].MENTION_ME_SERVER
export const INSPECTLET_ID = constants[NODE_ENV].INSPECTLET_ID
export const BREAD_API_JS = constants[NODE_ENV].BREAD_API_JS
export const BREAD_API_KEY = constants[NODE_ENV].BREAD_API_KEY
export const BREAD_API_PASS = constants[NODE_ENV].BREAD_API_PASS
export const BREAD2_API_JS = constants[NODE_ENV].BREAD2_API_JS
export const BREAD2_API_URL = constants[NODE_ENV].BREAD2_API_URL
export const BREAD_INTEGRATION_KEY = constants[NODE_ENV].BREAD_INTEGRATION_KEY
export const CBS_API_JS = constants[NODE_ENV].CBS_API_JS
export const shopUrl = BASE_URL
export const CLEMENTAINEIFRAMEURL = constants[NODE_ENV].CLEMENTAINEIFRAMEURL
export const APPLE_PAY_MERCHANT_IDENTIFIER = constants[NODE_ENV].APPLE_PAY_MERCHANT_IDENTIFIER
export const FOOTER_AEM_ENDPOINT = `${addressUrl}content/experience-fragments/eargo/us/en/site/footer/master/jcr:content/root/footer.model.json?env=${NODE_ENV}`
export const HEADER_AEM_ENDPOINT = `${addressUrl}content/experience-fragments/eargo/us/en/site/header/master/jcr:content/root/header.model.json?env=${NODE_ENV}`

export const CARD_BRANDS = {
  JCB: 'JCB',
  DINNER_CLUB: 'Diners Club',
  DINERS: 'diners',
  AMERICAN_EXPRESS: 'American Express',
  AMEX: 'amex',
  DISCOVER: 'Discover',
  MASTER_CARD: 'MasterCard',
  UNION_PAY: 'Unionpay',
  CU_PAY: 'cup',
  VISA: 'Visa'
}

export const CARD_CC_TYPES = {
  JCB: 'jcb',
  DINNER_CLUB: 'diners-club',
  DINERS: 'diners',
  AMERICAN_EXPRESS: 'american-express',
  AMEX: 'amex',
  DISCOVER: 'discover',
  MASTER_CARD: 'mastercard',
  UNION_PAY: 'unionpay',
  CU_PAY: 'cup',
  VISA: 'visa'
}

export const menuList = [
  {
    title: 'Hearing solutions',
    url: `${addressUrl}`,
    parentClass: 'li_solution',
    childClass: 'nav_solution',
    subMenuList: [
      {
        title: 'Hearing aids',
        suburl: `${addressUrl}products-hearing-aids`
      },
      {
        title: 'Eargo app',
        suburl: `${addressUrl}our-solutions/eargo-app`
      },
      {
        title: 'Hearing professionals',
        suburl: `${addressUrl}our-solutions/professional-support`
      }
    ]
  },
  {
    title: 'Eargo difference',
    url: `${addressUrl}`,
    parentClass: 'li_difference',
    childClass: 'nav_difference',
    subMenuList: [
      {
        title: 'How it works',
        suburl: `${addressUrl}eargo-difference`
      },
      {
        title: 'Compare us',
        suburl: `${addressUrl}hearing-aid-comparison`
      },
      {
        title: 'Reviews',
        suburl: `${addressUrl}reviews`
      }
    ]
  },
  {
    title: 'Shop',
    url: '/',
    parentClass: 'li_store',
    childClass: 'nav_store',
    subMenuList: [
      {
        address: ROUTES.EARGO_6,
        optionImage: 'https://assets.eargo.com/pdp/eargo6/eargo_6_new.png',
        optionImage_1: 'https://assets.eargo.com/pdp/eargo6/eargo_6_subnav.svg',
        optionTitle: 'Eargo 6',
        isNew: true
      },
      {
        address: ROUTES.EARGO_5,
        optionImage: 'https://assets.eargo.com/pdp/eargo5/E5_navbar.png',
        optionTitle: 'Eargo 5',
        isNew: true
      },
      {
        address: ROUTES.EARGO_NEO_HIFI,
        optionImage: 'https://assets.eargo.com/pdp/eargo5/NeoHiFi.png',
        optionTitle: 'Eargo Neo HiFi'
      },
      {
        address: ROUTES.EARGO_MAX,
        optionImage: 'https://assets.eargo.com/pdp/eargo5/max.png',
        optionTitle: 'Eargo Max'
      },
      {
        address: ROUTES.ACCESSORIES,
        optionImage: 'https://assets.eargo.com/pdp/eargo5/accessories.png',
        optionTitle: 'Accessories'
      }
    ]
  },
  {
    title: 'Hearing health',
    url: `${addressUrl}`,
    parentClass: 'li_health',
    childClass: 'nav_health',
    subMenuList: [
      {
        title: 'Hearing check',
        suburl: `${addressUrl}hearing-health/hearing-check`
      },
      {
        title: 'Hearing loss causes',
        suburl: `${addressUrl}hearing-health/causes-of-hearing-loss`
      },
      {
        title: 'Signs of hearing loss',
        suburl: `${addressUrl}hearing-health/signs-of-hearing-loss`
      },
      {
        title: 'Treating hearing loss',
        suburl: `${addressUrl}hearing-health/treatment-of-hearing-loss`
      },
      {
        title: 'Hearing loss FAQ',
        suburl: `${addressUrl}hearing-health/faq`
      }
    ]
  },
  {
    title: 'Support',
    url: `${addressUrl}guides`,
    parentClass: 'li_support',
    childClass: 'nav_support'
  }
  // {
  //   title: 'Insider',
  //   hoverTitle: 'Insider Blog',
  //   url: `${addressUrl}blog`,
  //   parentClass: 'li_blog li_support',
  //   childClass: 'nav_blog nav_support'
  // },
]

export const footerMenus = [
  {
    title: '1 (800) 903-6883',
    href: 'tel:1-800-903-6883',
    list: [
      { name: 'Support', href: `${addressUrl}guides#video_guides` }
    ]
  },
  {
    title: 'Shop',
    list: [
      { name: 'All hearing aids', href: `${addressUrl}products-hearing-aids` },
      { name: 'Eargo 6', href: ROUTES.EARGO_6 },
      { name: 'Eargo 5', href: ROUTES.EARGO_5 },
      { name: 'Eargo Neo HiFi', href: ROUTES.EARGO_NEO_HIFI },
      { name: 'Eargo Max', href: ROUTES.EARGO_MAX },
      { name: 'Eargo accessories', href: ROUTES.ACCESSORIES },
      { name: 'Reviews', href: `${addressUrl}reviews` }
    ]
  },
  {
    title: 'Education',
    list: [
      { name: 'How it works', href: `${addressUrl}eargo-difference` },
      { name: 'Hearing Screening', href: `${addressUrl}hearing-health/hearing-check` },
      { name: 'Blog', href: blogUrl },
      { name: 'FAQ', href: `${addressUrl}faq` },
      { name: 'Help', href: 'https://help.eargo.com' }
    ]
  },
  {
    title: 'About',
    list: [
      { name: 'Our brand', href: `${addressUrl}our-brand` },
      { name: 'About us', href: `${addressUrl}company/about-us` },
      { name: 'Careers', href: `${addressUrl}company/careers` },
      { name: 'Investor relations', href: investorUrl },
      { name: 'Financing', href: `${addressUrl}financing` }
      // { name: 'Press', href: `${addressUrl}press` },
      // { name: 'Insider Blog', href: blogUrl }
    ]
  },
  {
    title: 'Policies',
    list: [
      { name: 'Data request', href: `${addressUrl}datarequests` },
      { name: 'Terms and conditions', href: `${addressUrl}terms-of-use` },
      { name: 'Privacy policy', href: `${addressUrl}privacy` },
      { name: 'Warranty', href: `${addressUrl}warranty` },
      { name: 'Military discount', href: `${addressUrl}military-discount` },
      { name: 'Healthcare workers', href: `${addressUrl}healthcare-workers-discount` }
    ]
  }
]

export const copyrightLinks = [
  { name: 'Data Requests', href: `${addressUrl}datarequests` },
  { name: 'Terms And Conditions', href: `${addressUrl}terms-of-use` },
  { name: 'Privacy Policy', href: `${addressUrl}privacy` },
  { name: 'Warranty', href: `${addressUrl}warranty` }
]

export const COUPON_CODE = '' // keeping this for future use
export const COUNTRY_CODE = 'USA'
export const ZIP_SERVER_MSG = 'Given ZipCode and State combination is incorrect, please validate it.'

export const accessoriesMenuList = [
  {
    title: 'All Accessories'
  },
  {
    title: 'Shop By Category'
  },
  {
    title: 'Shop By Product',
    productCategories: [
      {
        heading: 'Accessories for Eargo 5, 6, and 7',
        name: 'eargo-5'
      },
      {
        heading: 'Accessories for All Other Models',
        name: 'other'
      },
      {
        heading: 'Accessories for Eargo SE',
        name: 'eargo-se'
      }
    ]
  }
]

export const ERROR_MESSAGE = 'Your card is invalid, please use a different one.'

// In case of an API failure while fetching the header data, we use this mock data.
// don't checkin any code which has references to local or other env's
export const footerMockData = {
  urlTwitter: 'https://twitter.com/eargo',
  urlFacebook: 'https://www.facebook.com/eargohearing',
  urlLinkedIn: 'https://www.linkedin.com/company/eargo',
  urlInstagram: 'https://www.instagram.com/eargo_hearing/',
  urlYoutube: 'https://www.youtube.com/c/EargoHearing',
  logo: 'https://www.eargo.com/content/dam/eargo/samples/logo_white.png',
  footerText: 'For mild to moderate high-frequency hearing loss. Will not restore normal hearing. Full benefit best obtained with frequent usage.',
  copyright: 'Copyright © 2022 Eargo Inc. All rights reserved.',
  phoneNumber: '1 (800) 903-6883',
  supportLinkText: 'Support',
  supportLinkTarget: 'https://www.eargo.com/guides',
  fontCallOutText: 'Inter Font',
  fontCallOutHoverText: 'Made with the loving generosity of <a href="https://fonts.google.com/specimen/Inter"> Inter Google Font',
  footerMenu: [
    {
      title: 'Shop',
      titleLink: null,
      columnLinkGroup: [
        {
          linkText: 'All hearing aids',
          linkUrl: 'https://www.eargo.com/products-hearing-aids'
        },
        {
          linkText: 'Eargo 6',
          linkUrl: 'https://shop.eargo.com/eargo-6'
        },
        {
          linkText: 'Eargo 5',
          linkUrl: 'https://shop.eargo.com/eargo-5'
        },
        {
          linkText: 'Eargo Neo HiFi',
          linkUrl: 'https://shop.eargo.com/eargo-neo-hifi'
        },
        {
          linkText: 'Eargo Max',
          linkUrl: 'https://shop.eargo.com/eargo-max'
        },
        {
          linkText: 'Eargo accessories',
          linkUrl: 'https://shop.eargo.com/accessories'
        },
        {
          linkText: 'Reviews',
          linkUrl: 'https://www.eargo.com/reviews'
        }
      ]
    },
    {
      title: 'Education',
      titleLink: null,
      columnLinkGroup: [
        {
          linkText: 'How it works',
          linkUrl: 'https://www.eargo.com/eargo-difference'
        },
        {
          linkText: 'Hearing Screening',
          linkUrl: 'https://www.eargo.com/hearing-health/hearing-check'
        },
        {
          linkText: 'Blog',
          linkUrl: 'https://www.eargo.com/blog/'
        },
        {
          linkText: 'FAQ',
          linkUrl: 'https://www.eargo.com/faq'
        },
        {
          linkText: 'Help',
          linkUrl: 'https://help.eargo.com/en/'
        }
      ]
    },
    {
      title: 'About',
      titleLink: null,
      columnLinkGroup: [
        {
          linkText: 'Our brand',
          linkUrl: 'https://www.eargo.com/our-brand'
        },
        {
          linkText: 'About us',
          linkUrl: 'https://www.eargo.com/company/about-us'
        },
        {
          linkText: 'Careers',
          linkUrl: 'https://www.eargo.com/company/careers'
        },
        {
          linkText: 'Investor relations',
          linkUrl: 'https://ir.eargo.com/'
        },
        {
          linkText: 'Financing',
          linkUrl: 'https://www.eargo.com/financing'
        }
      ]
    },
    {
      title: 'Policies',
      titleLink: null,
      columnLinkGroup: [
        {
          linkText: 'Data request',
          linkUrl: 'https://www.eargo.com/datarequests'
        },
        {
          linkText: 'Terms and conditions',
          linkUrl: 'https://www.eargo.com/terms-of-use'
        },
        {
          linkText: 'Privacy policy',
          linkUrl: 'https://www.eargo.com/privacy'
        },
        {
          linkText: 'Warranty',
          linkUrl: 'https://www.eargo.com/warranty'
        },
        {
          linkText: 'Military discount',
          linkUrl: 'https://www.eargo.com/military-discount'
        },
        {
          linkText: 'Healthcare workers',
          linkUrl: 'https://www.eargo.com/healthcare-workers-discount'
        }
      ]
    }
  ],
  twitterIcon: '/content/dam/eargo/samples/social-media-icons/twitter.png',
  facebookIcon: '/content/dam/eargo/samples/social-media-icons/facebook.png',
  linkedInIcon: '/content/dam/eargo/samples/social-media-icons/linkedin.png',
  instagramIcon: '/content/dam/eargo/samples/social-media-icons/instagram.png',
  youtubeIcon: '/content/dam/eargo/samples/social-media-icons/youtube.png',
  configured: true
}
// In case of an API failure while fetching the header data, we use this mock data.
// don't checkin any code which has references to local or other env's
export const headerMockData = {
  apiURI: 'https://shop.eargo.com/',
  logoLarge: 'https://www.eargo.com/content/dam/eargo/samples/eargo_orange_logo.svg',
  logoSmall: 'https://www.eargo.com/content/dam/eargo/samples/eargo5_title.png',
  phoneNumber: '1 (800) 903-6883',
  megamenuTitle: 'Products',
  megamenuOrder: 1,
  shopLink: 'https://www.eargo.com/products-hearing-aids',
  headerNavigation: [
    {
      title: 'The Eargo Difference',
      titleLink: 'https://www.eargo.com/eargo-difference',
      columnLinkGroup: [
        {
          linkText: 'Our vision',
          linkUrl: 'https://www.eargo.com/our-brand'
        },
        {
          linkText: 'The Eargo Difference',
          linkUrl: 'https://www.eargo.com/eargo-difference'
        },
        {
          linkText: 'Our hearing professionals',
          linkUrl: 'https://www.eargo.com/our-solutions/professional-support'
        },
        {
          linkText: 'Reviews',
          linkUrl: 'https://www.eargo.com/reviews'
        }
      ]
    },
    {
      title: 'Video guides',
      titleLink: 'https://www.eargo.com/guides',
      columnLinkGroup: []
    },
    {
      title: 'Blog',
      titleLink: 'https://www.eargo.com/blog/',
      columnLinkGroup: []
    }
  ],
  featuredHeaderImage: '/content/dam/eargo/samples/Eargo7logo.png',
  featuredProductName: 'Eargo 7',
  featuredProductImage: '/content/dam/eargo/samples/Eargo_7_redesign_logo.jpg',
  featuredProductDescription: "Eargo's 7 Sound Adjust+ with Clarity mode automatically optimize your device to your environment.",
  featuredProductLinkText: 'Shop now',
  featuredProductLink: 'https://shop.eargo.com/eargo-7',
  shopAdditionalProducts: [
    {
      image: '/content/dam/eargo/samples/Eargo_6_redesign_logo.jpg',
      title: 'Eargo 6',
      titleLogo: '/content/dam/eargo/samples/Eargo 6 Logo Black.svg',
      link: 'https://shop.eargo.com/eargo-6'
    },
    {
      image: '/content/dam/eargo/samples/Eargo_5_redesign_logo.jpg',
      title: 'Eargo 5',
      titleLogo: '/content/dam/eargo/samples/Eargo 5 Logo Black.svg',
      link: 'https://shop.eargo.com/eargo-5'
    },
    {
      image: '/content/dam/eargo/samples/accessory_redesign.jpg',
      title: 'Accessories',
      link: 'https://shop.eargo.com/accessories'
    }
  ]
}

export const supportTabList = [
  {
    title: 'Support requests'
  },
  {
    title: 'Video guides'
  },
  {
    title: 'Download app'
  }
]

export const supportVideoHeading = 'STARTER GUIDES FOR:'
export const supportVideoSubHeading = 'Watch and learn with our online video guides and tutorials that are unique to your Eargos.'

export const createSupportHeading = 'NEED SOME HELP?'
export const createSupportSubHeading = 'Send us a Support Request and one of our Customer Care Specialists will contact you as soon as possible.'

export const createActiveSupportHeading = 'Recent support requests'

export const downloadSupportHeading = 'APP FOR:'
export const downloadSupportSubHeading = 'Switch to your mobile device to download the free Eargo app and personalize your hearing devices for your preferences and lifestyle.'
export const MobileViewSubHeading = 'Download the Eargo app using one of the links below to personalize Eargo for your hearing preferences and lifestyle.'

export const neoAppStore = 'https://apps.apple.com/us/app/eargo-neo-hifi/id1437680985'
export const neoPlayStore = 'https://play.google.com/store/apps/details?id=com.eargo.app'
export const eargoAppStore = 'https://apps.apple.com/us/app/eargo/id1529890348'
export const eargoPlayStore = 'https://play.google.com/store/apps/details?id=com.eargo.app.android'

export const isWebP = () => {
  const { Modernizr } = window
  if (!!Modernizr &&
    typeof Modernizr !== 'undefined' &&
    typeof Modernizr !== 'undefined') {
    return !!Modernizr.webp
  }
  return false
}

export const SUPPORT_CONTENT = [
  { // Eargo 5 content
    title: 'WATCH AND LEARN*',
    product_class: 'eargo_5',
    content: [
      {
        title: 'WATCH AND LEARN*',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        note: '*Please be advised that the devices and accessories shown in these videos may be different from those in your Eargo box.',
        info: [
          {
            video: 'https://player.vimeo.com/external/516509198.hd.mp4?s=e7104b912ec53b9562790bd13d30eae07f21e8cc&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/WHAT_S+INCLUDED.png',
            img_alt: 'Eargo 5 Guides 0 - WHAT\'S INCLUDED',
            title: 'WHAT\'S INCLUDED'
          },
          {
            video: 'https://player.vimeo.com/external/516510539.hd.mp4?s=8236c653084ad21e9c14402c649a4ba63a914803&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/WELCOME_VIDEO.png',
            img_alt: 'Eargo 5 Guides 1 - WELCOME TO EARGO',
            title: 'WELCOME TO EARGO'
          }, {
            video: 'https://player.vimeo.com/external/516509694.hd.mp4?s=cf9c9198a18f2226693664d14d85135f6e24d6c2&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/INSERT_REMOVE_DEVICES.png',
            img_alt: 'Eargo 5 Guides 2 -INSERT AND REMOVE DEVICES',
            title: 'INSERT AND REMOVE DEVICES'
          }, {
            video: 'https://player.vimeo.com/external/516510074.hd.mp4?s=4c30b356bfed3fc6921f65f0d3dcda5183eba7c0&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/GET_TO_KNOW_YOUR_CHARGER.png',
            img_alt: 'Eargo 5 Guides 3 - GET TO KNOW YOUR CHARGER',
            title: 'GET TO KNOW YOUR CHARGER'
          }, {
            video: 'https://player.vimeo.com/external/516511109.hd.mp4?s=4c1caad50c0898f36c1106966f4175ddbe67f16c&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/FIND_THE_PERFECT_FIT.png',
            img_alt: 'Eargo 5 Guides 4 - FIND THE PERFECT FIT',
            title: 'FIND THE PERFECT FIT'
          }, {
            video: 'https://player.vimeo.com/external/516511768.hd.mp4?s=2ce2df6bdb70dbc79496ac8603f85c319775181e&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/ADJUST_THE_PROGRAM.png',
            img_alt: 'Eargo 5 Guides 5 - ADJUST THE PROGRAM',
            title: 'ADJUST THE PROGRAM'
          }, {
            video: 'https://player.vimeo.com/external/516512873.hd.mp4?s=f6709294ec80099858ccdd195710a76461552c4d&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/USE_THE_PHONE.png',
            img_alt: 'Eargo 5 Guides 6 - USE THE PHONE',
            title: 'USE THE PHONE'
          }, {
            video: 'https://player.vimeo.com/external/516513335.hd.mp4?s=05c77039152b73ac45722ca7f0a9fe16b9283ce1&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/ADAPT_TO_YOUR_DEVICES.png',
            img_alt: 'Eargo 5 Guides 7 - ADAPT TO YOUR DEVICES',
            title: 'ADAPT TO YOUR DEVICES'
          }, {
            video: 'https://player.vimeo.com/external/516513854.hd.mp4?s=a0d54dd8f3e4c58e000ac74bf68378be814d333c&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/DAILY_CARE.png',
            img_alt: 'Eargo 5 Guides 8 - DAILY CARE',
            title: 'DAILY CARE'
          }, {
            video: 'https://player.vimeo.com/external/516471250.hd.mp4?s=de3b046d6fa9c00127f9e164d5cb392a1d110e3c&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/REMOVE_REPLACE_PETALS.png',
            img_alt: 'Eargo 5 Guides 9 - REMOVE AND REPLACE PETALS',
            title: 'REMOVE AND REPLACE PETALS'
          }, {
            video: 'https://player.vimeo.com/external/516514438.hd.mp4?s=d424e313bede5383e91220a766407aee158fe4e9&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/REMOVE_REPLACE_MIC_CAPS..png',
            img_alt: 'Eargo 5 Guides 10 - REMOVE AND REPLACE MIC CAPS',
            title: 'REMOVE AND REPLACE MIC CAPS'
          }, {
            video: 'https://player.vimeo.com/external/516514949.hd.mp4?s=9f52022038413aa5eafc9f6f9263871995cd2a0e&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/ADDITIONAL_CARE.png',
            img_alt: 'Eargo 5 Guides 11 - ADDITIONAL CARE',
            title: 'ADDITIONAL CARE'
          }
        ]
      }
    //   {
    //     title: 'USER GUIDE',
    //     type: 'guide',
    //     class1: 'guide_section',
    //     class2: 'guide_selector',
    //     class3: 'guide_content',
    //     info: [
    //       {
    //         name: false,
    //         icon: 'https://assets.eargo.com/corp/guides_page/guide_neo_icon.png',
    //         guide: process.env.PUBLIC_URL + '/assets/EARGO_5_User_Guide.pdf'
    //       }
    //     ]
    //   }
    ],
    device: 0
  },
  { // Eargo 6 content
    title: 'WATCH AND LEARN*',
    product_class: 'eargo_6',
    content: [
      {
        title: 'WATCH AND LEARN*',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        note: '*Please be advised that the devices and accessories shown in these videos may be different from those in your Eargo box.',
        info: [
          {
            video: 'https://player.vimeo.com/external/516509198.hd.mp4?s=e7104b912ec53b9562790bd13d30eae07f21e8cc&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/WHAT_S+INCLUDED.png',
            img_alt: 'Eargo 5 Guides 0 - WHAT\'S INCLUDED',
            title: 'WHAT\'S INCLUDED'
          },
          {
            video: 'https://player.vimeo.com/external/516510539.hd.mp4?s=8236c653084ad21e9c14402c649a4ba63a914803&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/WELCOME_VIDEO.png',
            img_alt: 'Eargo 5 Guides 1 - WELCOME TO EARGO',
            title: 'WELCOME TO EARGO'
          }, {
            video: 'https://player.vimeo.com/external/516509694.hd.mp4?s=cf9c9198a18f2226693664d14d85135f6e24d6c2&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/INSERT_REMOVE_DEVICES.png',
            img_alt: 'Eargo 5 Guides 2 -INSERT AND REMOVE DEVICES',
            title: 'INSERT AND REMOVE DEVICES'
          }, {
            video: 'https://player.vimeo.com/external/516510074.hd.mp4?s=4c30b356bfed3fc6921f65f0d3dcda5183eba7c0&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/GET_TO_KNOW_YOUR_CHARGER.png',
            img_alt: 'Eargo 5 Guides 3 - GET TO KNOW YOUR CHARGER',
            title: 'GET TO KNOW YOUR CHARGER'
          }, {
            video: 'https://player.vimeo.com/external/516511109.hd.mp4?s=4c1caad50c0898f36c1106966f4175ddbe67f16c&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/FIND_THE_PERFECT_FIT.png',
            img_alt: 'Eargo 5 Guides 4 - FIND THE PERFECT FIT',
            title: 'FIND THE PERFECT FIT'
          }, {
            video: 'https://player.vimeo.com/external/516511768.hd.mp4?s=2ce2df6bdb70dbc79496ac8603f85c319775181e&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/ADJUST_THE_PROGRAM.png',
            img_alt: 'Eargo 5 Guides 5 - ADJUST THE PROGRAM',
            title: 'ADJUST THE PROGRAM'
          }, {
            video: 'https://player.vimeo.com/external/516512873.hd.mp4?s=f6709294ec80099858ccdd195710a76461552c4d&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/USE_THE_PHONE.png',
            img_alt: 'Eargo 5 Guides 6 - USE THE PHONE',
            title: 'USE THE PHONE'
          }, {
            video: 'https://player.vimeo.com/external/516513335.hd.mp4?s=05c77039152b73ac45722ca7f0a9fe16b9283ce1&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/ADAPT_TO_YOUR_DEVICES.png',
            img_alt: 'Eargo 5 Guides 7 - ADAPT TO YOUR DEVICES',
            title: 'ADAPT TO YOUR DEVICES'
          }, {
            video: 'https://player.vimeo.com/external/516513854.hd.mp4?s=a0d54dd8f3e4c58e000ac74bf68378be814d333c&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/DAILY_CARE.png',
            img_alt: 'Eargo 5 Guides 8 - DAILY CARE',
            title: 'DAILY CARE'
          }, {
            video: 'https://player.vimeo.com/external/516471250.hd.mp4?s=de3b046d6fa9c00127f9e164d5cb392a1d110e3c&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/REMOVE_REPLACE_PETALS.png',
            img_alt: 'Eargo 5 Guides 9 - REMOVE AND REPLACE PETALS',
            title: 'REMOVE AND REPLACE PETALS'
          }, {
            video: 'https://player.vimeo.com/external/516514438.hd.mp4?s=d424e313bede5383e91220a766407aee158fe4e9&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/REMOVE_REPLACE_MIC_CAPS..png',
            img_alt: 'Eargo 5 Guides 10 - REMOVE AND REPLACE MIC CAPS',
            title: 'REMOVE AND REPLACE MIC CAPS'
          }, {
            video: 'https://player.vimeo.com/external/516514949.hd.mp4?s=9f52022038413aa5eafc9f6f9263871995cd2a0e&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/ADDITIONAL_CARE.png',
            img_alt: 'Eargo 5 Guides 11 - ADDITIONAL CARE',
            title: 'ADDITIONAL CARE'
          }
        ]
      }
    //   {
    //     title: 'USER GUIDE',
    //     type: 'guide',
    //     class1: 'guide_section',
    //     class2: 'guide_selector',
    //     class3: 'guide_content',
    //     info: [
    //       {
    //         name: false,
    //         icon: 'https://assets.eargo.com/corp/guides_page/guide_neo_icon.png',
    //         guide: process.env.PUBLIC_URL + '/assets/EARGO_5_User_Guide.pdf'
    //       }
    //     ]
    //   }
    ],
    device: 1
  },
  { // Eargo NEO HiFi content
    title: 'WATCH AND LEARN*',
    product_class: 'eargo_neo_hifi',
    content: [
      {
        title: 'WATCH AND LEARN*',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        note: '*Please be advised that the devices and accessories shown in these videos may be different from those in your Eargo box.',
        info: [
          {
            video: 'https://player.vimeo.com/video/399501471?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/welcome_call.webp'
              : 'https://assets.eargo.com/corp/guides_page/welcome_call.jpg',
            img_alt: 'Eargo Neo Guides 0 - Welcome Call',
            title: 'Welcome Call'
          },
          {
            video: 'https://player.vimeo.com/video/336626395?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/introduction.webp'
              : 'https://assets.eargo.com/corp/guides_page/INTRODUCTION.jpg',
            img_alt: 'Eargo Neo Guides 0 - Introduction',
            title: 'INTRODUCTION'
          }, {
            video: 'https://player.vimeo.com/video/336626379?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/preparing_your_ears.webp'
              : 'https://assets.eargo.com/corp/guides_page/preparing_your_ears.jpg',
            img_alt: 'Eargo Neo Guides 1 - Preparing Your Ears',
            title: 'PREPARING YOUR EARS'
          }, {
            video: 'https://player.vimeo.com/video/336626252?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/getting_to_know_your_eargo.webp'
              : 'https://assets.eargo.com/corp/guides_page/getting_to_know_your_eargo.jpg',
            img_alt: 'Eargo Neo Guides 2 - Getting to Know Your Eargo(s)',
            title: 'GETTING TO KNOW YOUR EARGO(S)'
          }, {
            video: 'https://player.vimeo.com/video/336626356?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/lets_get_all_charged_up.webp'
              : 'https://assets.eargo.com/corp/guides_page/lets_get_all_charged_up.jpg',
            img_alt: 'Eargo Neo Guides 3 - Let\'s Get All Charged Up',
            title: 'LET\'S GET ALL CHARGED UP'
          }, {
            video: 'https://player.vimeo.com/video/336626077?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/inserting_and_removing_your_eargo_devices.webp'
              : 'https://assets.eargo.com/corp/guides_page/inserting_and_removing_your_eargo_devices.jpg',
            img_alt: 'Eargo Neo Guides 4 - Inserting & Removing Your Eargo Devices',
            title: 'INSERTING & REMOVING YOUR EARGO DEVICES'
          }, {
            video: 'https://player.vimeo.com/video/336626271?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/finding_the_perfect_fit.webp'
              : 'https://assets.eargo.com/corp/guides_page/finding_the_perfect_fit.jpg',
            img_alt: 'Eargo Neo Guides 5 - Finding the Perfect Fit',
            title: 'FINDING THE PERFECT FIT'
          }, {
            video: 'https://player.vimeo.com/video/336626223?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/adjusting_the_programs.webp'
              : 'https://assets.eargo.com/corp/guides_page/adjusting_the_programs.jpg',
            img_alt: 'Eargo Neo Guides 6 - Adjusting the Programs',
            title: 'ADJUSTING THE PROGRAMS'
          }, {
            video: 'https://player.vimeo.com/video/336626316?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/your_first_month_and_beyond.webp'
              : 'https://assets.eargo.com/corp/guides_page/your_first_month_and_beyond.jpg',
            img_alt: 'Eargo Neo Guides 7 - Your First Month and Beyond',
            title: 'YOUR FIRST MONTH AND BEYOND'
          }, {
            video: 'https://player.vimeo.com/video/336626126?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/eargo_care.webp'
              : 'https://assets.eargo.com/corp/guides_page/eargo_care.jpg',
            img_alt: 'Eargo Neo Guides 8 - Eargo Care',
            title: 'EARGO CARE'
          }, {
            video: 'https://player.vimeo.com/video/336626297?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/one_last_thing.webp'
              : 'https://assets.eargo.com/corp/guides_page/one_last_thing.jpg',
            img_alt: 'Eargo Neo Guides 9 - One Last Thing',
            title: 'ONE LAST THING'
          }
        ]
      }
      // {
      //   title: 'USER GUIDE',
      //   type: 'guide',
      //   class1: 'guide_section',
      //   class2: 'guide_selector',
      //   class3: 'guide_content',
      //   info: [
      //     {
      //       name: false,
      //       icon: 'https://assets.eargo.com/corp/guides_page/guide_hifi_icon.png',
      //       guide: process.env.PUBLIC_URL + '/assets/Eargo-Neo-HiFi-User-Guide.pdf'
      //     }
      //   ]
      // },
      // {
      //   title: 'FAQS',
      //   type: 'faq',
      //   class1: 'faq-section',
      //   class2: 'faq_selector',
      //   class3: 'faq_content',
      //   info: [
      //     {
      //       name: 'Will my Eargo devices remember their sound level?',
      //       href: 'https://help.eargo.com/en/articles/2531749-will-my-eargo-devices-remember-their-sound-level'
      //     },
      //     {
      //       name: 'Can Eargo Neo HiFi connect to my cell phone?',
      //       href: 'https://help.eargo.com/en/articles/2545978-can-eargo-neo-connect-to-my-cell-phone'
      //     },
      //     {
      //       name: 'What do I do about earwax on my Eargos?',
      //       href: 'http://help.eargo.com/en/articles/2545972-eargo-and-earwax'
      //     },
      //     {
      //       name: 'What if there are no LEDs showing?',
      //       href: 'http://help.eargo.com/en/articles/2453656-what-if-there-are-no-leds-showing'
      //     },
      //     {
      //       name: 'What setting should I use while talking on the phone?',
      //       href: 'https://help.eargo.com/en/articles/2453375-what-about-when-i-m-on-the-phone'
      //     }
      //   ]
      // }
    ],
    device: 2
  },
  { // Eargo NEO content
    product_class: 'eargo_neo',
    content: [
      {
        title: 'WATCH AND LEARN',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        info: [
          {
            video: 'https://player.vimeo.com/video/399501471?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/welcome_call.webp'
              : 'https://assets.eargo.com/corp/guides_page/welcome_call.jpg',
            img_alt: 'Eargo Neo Guides 0 - Welcome Call',
            title: 'Welcome Call'
          },
          {
            video: 'https://player.vimeo.com/video/336626395?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/introduction.webp'
              : 'https://assets.eargo.com/corp/guides_page/INTRODUCTION.jpg',
            img_alt: 'Eargo Neo Guides 0 - Introduction',
            title: 'INTRODUCTION'
          }, {
            video: 'https://player.vimeo.com/video/336626379?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/preparing_your_ears.webp'
              : 'https://assets.eargo.com/corp/guides_page/preparing_your_ears.jpg',
            img_alt: 'Eargo Neo Guides 1 - Preparing Your Ears',
            title: 'PREPARING YOUR EARS'
          }, {
            video: 'https://player.vimeo.com/video/336626252?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/getting_to_know_your_eargo.webp'
              : 'https://assets.eargo.com/corp/guides_page/getting_to_know_your_eargo.jpg',
            img_alt: 'Eargo Neo Guides 2 - Getting to Know Your Eargo(s)',
            title: 'GETTING TO KNOW YOUR EARGO(S)'
          }, {
            video: 'https://player.vimeo.com/video/336626356?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/lets_get_all_charged_up.webp'
              : 'https://assets.eargo.com/corp/guides_page/lets_get_all_charged_up.jpg',
            img_alt: 'Eargo Neo Guides 3 - Let\'s Get All Charged Up',
            title: 'LET\'S GET ALL CHARGED UP'
          }, {
            video: 'https://player.vimeo.com/video/336626077?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/inserting_and_removing_your_eargo_devices.webp'
              : 'https://assets.eargo.com/corp/guides_page/inserting_and_removing_your_eargo_devices.jpg',
            img_alt: 'Eargo Neo Guides 4 - Inserting & Removing Your Eargo Devices',
            title: 'INSERTING & REMOVING YOUR EARGO DEVICES'
          }, {
            video: 'https://player.vimeo.com/video/336626271?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/finding_the_perfect_fit.webp'
              : 'https://assets.eargo.com/corp/guides_page/finding_the_perfect_fit.jpg',
            img_alt: 'Eargo Neo Guides 5 - Finding the Perfect Fit',
            title: 'FINDING THE PERFECT FIT'
          }, {
            video: 'https://player.vimeo.com/video/336626223?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/adjusting_the_programs.webp'
              : 'https://assets.eargo.com/corp/guides_page/adjusting_the_programs.jpg',
            img_alt: 'Eargo Neo Guides 6 - Adjusting the Programs',
            title: 'ADJUSTING THE PROGRAMS'
          }, {
            video: 'https://player.vimeo.com/video/336626316?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/your_first_month_and_beyond.webp'
              : 'https://assets.eargo.com/corp/guides_page/your_first_month_and_beyond.jpg',
            img_alt: 'Eargo Neo Guides 7 - Your First Month and Beyond',
            title: 'YOUR FIRST MONTH AND BEYOND'
          }, {
            video: 'https://player.vimeo.com/video/336626126?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/eargo_care.webp'
              : 'https://assets.eargo.com/corp/guides_page/eargo_care.jpg',
            img_alt: 'Eargo Neo Guides 8 - Eargo Care',
            title: 'EARGO CARE'
          }, {
            video: 'https://player.vimeo.com/video/336626297?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/one_last_thing.webp'
              : 'https://assets.eargo.com/corp/guides_page/one_last_thing.jpg',
            img_alt: 'Eargo Neo Guides 9 - One Last Thing',
            title: 'ONE LAST THING'
          }
        ]
      }
      // {
      //   title: 'USER GUIDE',
      //   type: 'guide',
      //   class1: 'guide_section',
      //   class2: 'guide_selector',
      //   class3: 'guide_content',
      //   info: [
      //     {
      //       name: false,
      //       icon: 'https://assets.eargo.com/corp/guides_page/guide_neo_icon.png',
      //       guide: process.env.PUBLIC_URL + '/assets/EARGO_Neo_User_Guide.pdf'
      //     }
      //   ]
      // },
      // {
      //   title: 'FAQS',
      //   type: 'faq',
      //   class1: 'faq-section',
      //   class2: 'faq_selector',
      //   class3: 'faq_content',
      //   info: [
      //     {
      //       name: 'Can Eargo Neo connect to my cell phone?',
      //       href: 'https://help.eargo.com/en/articles/2545978-can-eargo-neo-connect-to-my-cell-phone'
      //     },
      //     {
      //       name: 'What do I do about earwax on my Eargos?',
      //       href: 'http://help.eargo.com/en/articles/2545972-eargo-and-earwax'
      //     },
      //     {
      //       name: 'Why am I not getting any sound out of my Eargo device?',
      //       href: 'http://help.eargo.com/en/articles/2453689-i-m-not-getting-any-sound-out-of-my-eargo-device'
      //     },
      //     {
      //       name: 'Will my Eargo devices remember their sound level?',
      //       href: 'https://help.eargo.com/en/articles/2531749-will-my-eargo-devices-remember-their-sound-level'
      //     },
      //     {
      //       name: 'What does the warranty cover?',
      //       href: 'https://help.eargo.com/en/articles/2531706-what-does-the-warranty-cover'
      //     }
      //   ]
      // }
    ],
    device: 3
  },
  { // Eargo MAX content
    product_class: 'eargo_max',
    content: [
      {
        title: 'WATCH AND LEARN',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        info: [
          {
            video: 'https://player.vimeo.com/video/399501471?autoplay=1',
            image: isWebP
              ? 'https://assets.eargo.com/corp/guides_page/welcome_call.webp'
              : 'https://assets.eargo.com/corp/guides_page/welcome_call.jpg',
            img_alt: 'Eargo Neo Guides 0 - Welcome Call',
            title: 'Welcome Call'
          },
          {
            video: 'https://player.vimeo.com/video/336623515?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/INTRODUCTION.jpg',
            img_alt: 'Eargo Max Guides 0 - Introduction',
            title: 'Introduction'
          }, {
            video: 'https://player.vimeo.com/video/336623510?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_1.jpg',
            img_alt: 'Eargo Max Guides 1 - Prepare Your Ears',
            title: 'Preparing Your Ears'
          }, {
            video: 'https://player.vimeo.com/video/336623503?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_2.jpg',
            img_alt: 'Eargo Max Guides 2 - Getting To Know Your Eargos',
            title: 'Getting to Know Your Eargo(s)'
          }, {
            video: 'https://player.vimeo.com/video/336623495?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_3.jpg',
            img_alt: 'Eargo Max Guides 3 - Let\'s Get All Charged Up',
            title: 'Let\'s Get All Charged Up'
          }, {
            video: 'https://player.vimeo.com/video/336623478?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_4.jpg',
            img_alt: 'Eargo Max Guides 4 - Inserting And Moving Your Eargo Devices',
            title: 'Inserting & Removing Your Eargo Devices'
          }, {
            video: 'https://player.vimeo.com/video/336623485?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_5.jpg',
            img_alt: 'Eargo Max Guides 5 - Finding The Perfect Fit',
            title: 'Finding the Perfect Fit'
          }, {
            video: 'https://player.vimeo.com/video/336623430?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_6.jpg',
            img_alt: 'Eargo Max Guides 6 - Adjusting The Sound Profile',
            title: 'Adjusting The Sound Profile'
          }, {
            video: 'https://player.vimeo.com/video/336623440?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_7.jpg',
            img_alt: 'Eargo Max Guides 7 - Your First Month And Beyond',
            title: 'Your First Month and Beyond'
          }, {
            video: 'https://player.vimeo.com/video/336623455?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_8.jpg',
            img_alt: 'Eargo Max Guides 8 - Eargo Care',
            title: 'Eargo Care'
          }, {
            video: 'https://player.vimeo.com/video/336623469?autoplay=1',
            image: 'https://assets.eargo.com/corp/guides_page/SECTION_9.jpg',
            img_alt: 'Eargo Max Guides 9 - One Last Thing',
            title: 'One Last Thing'
          }
        ]
      }
    ],
    device: 4
  },
  { // Eargo 7 content
    title: 'WATCH AND LEARN*',
    product_class: 'eargo_7',
    content: [
      {
        title: 'WATCH AND LEARN*',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        note: '*Please be advised that the devices and accessories shown in these videos may be different from those in your Eargo box.',
        info: [
          {
            video: 'https://player.vimeo.com/external/516509198.hd.mp4?s=e7104b912ec53b9562790bd13d30eae07f21e8cc&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/WHAT_S+INCLUDED.png',
            img_alt: 'Eargo 7 Guides 0 - WHAT\'S INCLUDED',
            title: 'WHAT\'S INCLUDED'
          },
          {
            video: 'https://player.vimeo.com/external/516510539.hd.mp4?s=8236c653084ad21e9c14402c649a4ba63a914803&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/WELCOME_VIDEO.png',
            img_alt: 'Eargo 7 Guides 1 - WELCOME TO EARGO',
            title: 'WELCOME TO EARGO'
          },
          {
            video: 'https://player.vimeo.com/external/516509694.hd.mp4?s=cf9c9198a18f2226693664d14d85135f6e24d6c2&profile_id=175',
            image: 'https://assets.eargo.com/corp/guides_page/eargo5/INSERT_REMOVE_DEVICES.png',
            img_alt: 'Eargo 7 Guides 2 -INSERT AND REMOVE DEVICES',
            title: 'INSERT AND REMOVE DEVICES'
          }
        ]
      }
    ],
    device: 5
  },
  { // Eargo SE content
    title: 'WATCH AND LEARN*',
    product_class: 'eargo_se',
    content: [
      {
        title: 'WATCH AND LEARN*',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        note: '*Please be advised that the devices and accessories shown in these videos may be different from those in your Eargo box.',
        info: [
          {
            video: 'https://player.vimeo.com/video/857700274?h=045e00d345&autoplay=1',
            image: 'https://assets.eargo.com/guides/eargo-se/orientation-call.png',
            img_alt: 'Eargo SE Guides - Orientation Call',
            title: 'Orientation Call'
          },
          {
            video: 'https://player.vimeo.com/video/857699817?h=16d30902d1&autoplay=1',
            image: 'https://assets.eargo.com/guides/eargo-se/get-to-know-your-charger.png',
            img_alt: 'Eargo SE Guides - Get to Know Your Charger',
            title: 'Get to Know Your Charger'
          },
          {
            video: 'https://player.vimeo.com/video/857622797?h=fcb3e7477f&autoplay=1',
            image: 'https://assets.eargo.com/guides/eargo-se/whats-in-the-box.png',
            img_alt: 'Eargo SE Guides -What\'s In the Box',
            title: 'What\'s In the Box'
          }
        ]
      }
    ],
    device: 6
  },
  { // Link By Eargo content
    title: 'WATCH AND LEARN*',
    product_class: 'link_by_eargo',
    content: [
      {
        title: 'WATCH AND LEARN*',
        type: 'video',
        class1: 'video_section',
        class2: 'video_selector',
        class3: 'video_container',
        note: '*Please be advised that the devices and accessories shown in these videos may be different from those in your Eargo box.',
        info: [
          {
            video: 'https://player.vimeo.com/video/898206328?h=5848a5cf95&autoplay=1',
            image: 'https://assets.eargo.com/guides/link-by-eargo/introduction.png',
            img_alt: 'Link By Eargo Guides - Introduction',
            title: 'Introduction'
          },
          {
            video: 'https://player.vimeo.com/video/898206424?h=7ceb3f13c0&autoplay=1',
            image: 'https://assets.eargo.com/guides/link-by-eargo/get-to-know-your-earbuds.png',
            img_alt: 'Link By Eargo Guides - Get to Know Your Earbuds',
            title: 'Get to Know Your Earbuds'
          },
          {
            video: 'https://player.vimeo.com/video/898206787?h=51d2ca8f45&autoplay=1',
            image: 'https://assets.eargo.com/guides/link-by-eargo/charge-your-earbuds.png',
            img_alt: 'Link By Eargo Guides -Charge Your Earbuds',
            title: 'Charge Your Earbuds'
          }
        ]
      }
    ],
    device: 7
  }
]

export const stepsArray = [
  { name: 'Processing', value: 'pre_transit' },
  { name: 'In Transit', value: 'in_transit' },
  { name: 'Out for Delivery', value: 'out_of_delivery' },
  { name: 'Delivered', value: 'delivered' }
]

export const checkLeadIdforDocument = (value) => {
  return [
    { action: 'link', to: '/my-info/dashboard', value: 'dashboard', label: 'Dashboard', show: true },
    { action: 'link', to: '/my-info', value: 'myPayment', label: 'Account info', show: true },
    { action: 'link', to: '/my-info/order', value: 'managePassword', label: 'Orders', show: true },
    { action: 'link', to: '/my-info/documents', value: 'uploadDocument', label: 'Documents', show: !!(value && value.data && value.data.Email) },
    { action: 'link', to: ['/my-info/support', '/my-info/support/form', '/my-info/support/:id'], value: 'support', label: 'Support', show: true },
    { action: 'logout', to: null, value: 'logout', label: 'Log out', show: true }
  ]
}

export const checkLeadIdforDocumentUpload = (value) => {
  return [
    { image: 'https://assets.eargo.com/account/dashboard_icon.jpg', action: 'link', to: '/my-info/dashboard', title: 'Dashboard', show: true },
    { image: 'https://assets.eargo.com/new_account/user.png', action: 'link', to: '/my-info', title: 'Account Info', show: true },
    { image: 'https://assets.eargo.com/new_account/orders_list.png', action: 'link', to: '/my-info/order', title: 'Orders', show: true },
    { image: 'https://assets.eargo.com/account/insuranceform/document.png', action: 'link', to: '/my-info/documents', title: 'Documents', show: !!(value && value.data && value.data.Email) },
    {
      image: 'https://assets.eargo.com/new_account/support.png',
      action: 'link',
      to: '/my-info/support',
      title: 'Support',
      show: true
    },
    {
      image: 'https://assets.eargo.com/account/logout-icon.svg',
      action: 'logout',
      to: null,
      title: 'Log out',
      show: true
    }
  ]
}

export const ordersData = [
  {
    id: '1',
    orderDate: 'Apr. 13, 2022',
    price: '2,600.00',
    status: 'Apr. 20, 2022',
    orderId: 'S966240117',
    productImage:
      'https://assets.eargo.com/pdp/eargo6/eargo6_cart.png',
    name: 'Eargo NEO'
  },
  {
    id: '2',
    orderDate: 'Dec. 15, 2022',
    price: '2,600.00',
    status: 'Dec. 25, 2022',
    orderId: 'S695476028',
    productImage:
      'https://assets.eargo.com/pdp/eargo6/eargo6_cart.png',
    name: 'Eargo NEO'
  },
  {
    id: '3',
    orderDate: 'May. 03, 2022',
    price: '2,600.00',
    status: 'May. 13, 2022',
    orderId: 'S125812574',
    productImage:
      'https://assets.eargo.com/pdp/eargo6/eargo6_cart.png',
    name: 'Eargo NEO'
  }
]

export const orderOptions = [
  { value: 'all', label: 'All' },
  { value: 'return', label: 'Returns' },
  { value: 'exchange', label: 'Exchanges' }
]

export const getOrderStatus = (status, order) => {
  if (order && (order).startsWith('RC')) {
    switch (status) {
      case 'Cancelled':
        return 'Return Cancelled'
      case 'Delivered':
        return 'Refund issued'
      case 'Hold for Shipment':
        return 'Processing return'
      case 'New':
        return 'Return request created - Awaiting device return'
      case 'Partial Payment':
        return 'Refund issued'
      case 'Pending Backorder':
      case 'Pending Hearing Screening':
      case 'Delivery Failed':
        return 'N/A'
      case 'Pending CC Action':
      case 'Pending payment':
      case 'Processing':
      case 'Returned':
      case 'Shipped':
      case 'Pending Waiver':
        return 'Processing refund'
      case 'Waiting for Customer':
        return 'Processing refund - waiting for customer'
      default:
        return status
    }
  }
  if (order && (order).startsWith('RE')) {
    switch (status) {
      case 'Cancelled':
        return 'RMA Cancelled'
      case 'Delivered':
        return 'Delivered'
      case 'Hold for Shipment':
        return 'Processing return'
      case 'New':
        return 'RMA created - Awaiting device return'
      case 'Partial Payment':
        return 'N/A'
      case 'Pending Backorder':
        return 'Processing RMA - Backorder'
      case 'Pending Hearing Screening':
        return 'N/A'
      case 'Delivery Failed':
        return 'Return to sender'
      case 'Pending CC Action':
      case 'Pending payment':
      case 'Processing':
      case 'Returned':
      case 'Shipped':
      case 'Pending Waiver':
        return 'Processing RMA  '
      case 'Waiting for Customer':
        return 'Processing RMA  - waiting for customer'
      default:
        return status
    }
  }
  return status
}

export const CAPTCHA_SITE_KEY = '6LdFqcUZAAAAALVRZ9ZAm7UpSNqSxzXexQK45JsO'
export const RESEND_TIME = 180000
export const AUTH0CLIENTID = constants[NODE_ENV].AUTH0CLIENTID
export const AUTH0DOMAIN = constants[NODE_ENV].AUTH0DOMAIN
export const AUTH0CONNECTION = constants[NODE_ENV].AUTH0CONNECTION

export const newGuidesLinks = {
  link_by_eargo: 'guides/link-by-eargo',
  eargo_se: 'guides/eargo-se',
  eargo_7: 'guides/eargo-7',
  eargo_6: 'guides/eargo-6',
  eargo_5: 'guides/eargo-5',
  eargo_neo_hifi: 'guides/eargo-neo-hifi',
  eargo_neo: 'guides/eargo-neo',
  eargo_max: 'guides/eargo-max',
  eargo_plus: 'guides/eargo-plus'
}
